!
<template>
  <div>
    <a-modal :visible="visiable" @ok="deleteFile" @cancel="handleCancel">
      <div class="row">
        <img src="@/assets/images/timeline/warn-icon.png" />
        <span>您确定要删除吗?删除后此操作无法撒消。</span>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';
import { message } from 'ant-design-vue';
import timelineApi from '@/api/timeline';

const props = defineProps({
  visiable: Boolean,
  deleteId: String,
});

const id = ref('');

const emits = defineEmits(['closeModal']);

const handleCancel = () => {
  emits('closeModal', false);
};

//删除指定记录
const deleteFile = () => {
  timelineApi
    .deleteFile('', {
      'ids[]': id.value,
    })
    .then(res => {
      console.log(res);
      if (res) {
        message.success('删除成功');
        emits('closeModal', true, [id.value].length);
      }
    });
};

watch(
  () => props.deleteId,
  (newVal, oldVal) => {
    id.value = newVal;
  }
);
</script>

<style lang="less" scoped>
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  img {
    width: 21px;
    height: 21px;
    object-fit: cover;
  }

  span {
    width: 384px;
    color: #3d3d3d;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
