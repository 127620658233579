<template>
  <div class="container">
    <div class="container-header">
      <a-select
        v-model:value="backlogType"
        style="width: 100px"
        @change="initData()"
        placeholder="类型"
      >
        <a-select-option :value="null">全部</a-select-option>
        <template v-for="(item, index) in backlogTypeList" :key="index">
          <a-select-option :value="item.itemCode">
            {{ item.itemNameCn }}
          </a-select-option>
        </template>
      </a-select>
      <a-range-picker style="width: 220px" v-model:value="backlogDate">
        <template #suffixIcon>
          <calendar-outlined class="ant-select-suffix" />
        </template>
      </a-range-picker>
      <a-input-search
        placeholder="搜索记录"
        style="width: 186px"
        allow-clear
        @search="value => searchBacklog({ search: value || null })"
      />
    </div>
    <div class="contain backlog-list" v-if="pageTotal != 0">
      <div class="dashline"></div>
      <template v-for="(groupItem, groupIndex) in recordList" :key="groupIndex">
        <div class="backlog-group">
          <div class="group-header">
            <span class="backlog-time">{{ groupItem.time }}</span>
            <span class="backlog-day">
              {{ weekdays[new Date(groupItem.time).getDay()] }}
            </span>
          </div>
          <template v-if="groupItem?.contactTodoMatterVoList?.length > 0">
            <div
              class="backlog-item"
              v-for="(itemItem, itemIndex) in groupItem.contactTodoMatterVoList"
              :key="itemIndex"
            >
              <div class='momentTitle'>{{itemItem.contactTodoMomentVo?.momentTitle}}</div>
              <div class="item-header">
                <div class="backlog-type">
                  <span>待办</span>
                </div>
              </div>
              <div class="row">
                <div
                  class="avatar-list"
                  :class="{
                    showMore:
                      itemItem?.personnelVoList?.length >
                      (itemItem?.personnelType ? 4 : 7),
                  }"
                >
                  <div
                    v-if="itemItem.personnelType === 1"
                    class="flexCalss"
                    :class="{
                      normalType: !itemItem.personnelType,
                      circleType: itemItem.personnelType,
                    }"
                  >
                    <div class="avatarClass" v-if="itemItem.connections">
                      <component
                        :is="UserOutlined"
                        class="iconClass"
                        @click="
                          handleGoToContact(
                            itemItem.connections,
                            itemItem.personnelType
                          )
                        "
                      >
                      </component>
                      <div
                        class="textMl mr-8"
                        @click="
                          handleGoToContact(
                            itemItem.connections,
                            itemItem.personnelType
                          )
                        "
                      >
                        {{ itemItem.connections.connectionsName }}
                      </div>
                    </div>
                    <div
                      class="avatarClass"
                      v-for="i in itemItem?.personnelVoList"
                      :key="i.id"
                    >
                      <a-avatar
                        v-if="i.avatar"
                        :src="setAvatar(i.avatar)"
                        @click="handleGoToContact(i)"
                        class="headerUrl"
                      />
                      <div
                        v-else
                        @click="handleGoToContact(i)"
                        class="headerText headerTextC"
                      >
                        <div v-if="i.name">
                          {{ i.name[0] }}
                        </div>
                      </div>
                      <div
                        class="textMl"
                        @click="handleGoToContact(i)"
                        v-if="itemItem?.personnelVoList.length === 1"
                      >
                        {{ i.name }}
                      </div>
                    </div>
                  </div>
                  <div v-else class="flexCalss">
                    <div
                      class="avatarClass"
                      v-for="i in itemItem?.personnelVoList"
                      :key="i.id"
                    >
                      <a-avatar
                        v-if="i.avatar"
                        :src="setAvatar(i.avatar)"
                        @click="handleGoToContact(i)"
                        class="headerUrl"
                      />
                      <div
                        v-else
                        @click="handleGoToContact(i)"
                        class="headerText"
                      >
                        <div v-if="i.name">
                          {{ i.name[0] }}
                        </div>
                      </div>
                      <div
                        class="textMl"
                        @click="handleGoToContact(i)"
                        v-if="itemItem?.personnelVoList?.length === 1"
                      >
                        {{ i.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item-content type-auto">
                  <div class="backlog-title" @click="openDetailModa(itemItem)">
                    <span>{{ itemItem.content }}</span>
                  </div>
                  <div
                    class="backlog-content"
                    v-if="
                      itemItem?.subItemsList?.length > 0 &&
                      itemItem?.subItemsList != null
                    "
                    @click="openDetailModa(itemItem)"
                  >
                    <template
                      v-for="(daibanItem, daibanIndex) in itemItem.subItemsList"
                      :key="daibanIndex"
                    >
                      <div class="row">
                        <img
                          src="@/assets/images/timeline/daiban-active.png"
                          alt=""
                          v-if="daibanItem.completed"
                        />
                        <img
                          src="@/assets/images/timeline/daiban.png"
                          v-else
                          alt=""
                        />
                        <span>{{ daibanItem.subContent }}</span>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="btn-group">
                  <a-button size="small" @click="openRebackModa(itemItem)">
                    <template #icon>
                      <RedoOutlined style="color: #575b66" />
                    </template>
                  </a-button>
                  <a-button
                    size="small"
                    @click="opendeleteModa(itemItem.id, 1)"
                  >
                    <template #icon>
                      <DeleteOutlined style="color: #575b66" />
                    </template>
                  </a-button>
                  <a-button
                    size="small"
                    @click="openChildModa(itemItem)"
                    v-if="!itemItem.contactTodoMomentVo"
                  >
                    <template #icon>
                      <PlusOutlined style="color: #575b66" />
                    </template>
                  </a-button>
                </div>
              </div>
              <div class="child-item" v-if="itemItem.contactTodoMomentVo">
                <div class="row">
                  <div
                    class="avatar-list"
                    :class="{
                      showMore:
                        itemItem?.contactTodoMomentVo?.momentContactVOList
                          ?.length >
                        (itemItem?.contactTodoMomentVo?.personnelType ? 4 : 7),
                    }"
                  >
                    <div
                      v-if="itemItem.contactTodoMomentVo.personnelType === 1"
                      class="flexCalss"
                      :class="{
                        normalType: !itemItem.personnelType,
                        circleType: itemItem.personnelType,
                      }"
                    >
                      <div
                        class="avatarClass"
                        v-if="itemItem.contactTodoMomentVo.connections"
                      >
                        <component
                          :is="UserOutlined"
                          class="iconClass"
                          @click="
                            handleGoToContact(
                              itemItem.contactTodoMomentVo.connections,
                              itemItem.contactTodoMomentVo.personnelType
                            )
                          "
                        >
                        </component>
                        <div
                          class="textMl mr-8"
                          @click="
                            handleGoToContact(
                              itemItem.contactTodoMomentVo.connections,
                              itemItem.contactTodoMomentVo.personnelType
                            )
                          "
                        >
                          {{
                            itemItem.contactTodoMomentVo.connections
                              .connectionsName
                          }}
                        </div>
                      </div>
                      <div
                        class="avatarClass"
                        v-for="i in itemItem.contactTodoMomentVo
                          ?.momentContactVOList"
                        :key="i.id"
                      >
                        <a-avatar
                          v-if="i.avatar"
                          :src="setAvatar(i.avatar)"
                          @click="handleGoToContact(i)"
                          class="headerUrl"
                        />
                        <div
                          v-else
                          @click="handleGoToContact(i)"
                          class="headerText headerTextC"
                        >
                          <div v-if="i.name">
                            {{ i.name[0] }}
                          </div>
                        </div>
                        <div
                          class="textMl"
                          @click="handleGoToContact(i)"
                          v-if="
                            itemItem.contactTodoMomentVo?.momentContactVOList
                              ?.length === 1
                          "
                        >
                          {{ i.name }}
                        </div>
                      </div>
                    </div>

                    <div v-else class="flexCalss">
                      <div
                        class="avatarClass"
                        v-for="i in itemItem.contactTodoMomentVo
                          ?.momentContactVOList"
                        :key="i.id"
                      >
                        <a-avatar
                          v-if="i.avatar"
                          :src="setAvatar(i.avatar)"
                          @click="handleGoToContact(i)"
                          class="headerUrl"
                        />
                        <div
                          v-else
                          @click="handleGoToContact(i)"
                          class="headerText"
                        >
                          <div v-if="i.name">
                            {{ i.name[0] }}
                          </div>
                        </div>
                        <div
                          class="textMl"
                          @click="handleGoToContact(i)"
                          v-if="
                            itemItem.contactTodoMomentVo?.momentContactVOList
                              ?.length === 1
                          "
                        >
                          {{ i.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-content type-auto">
                    <div
                      class="backlog-content"
                      @click="openDetailModa(itemItem)"
                    >
                      <span>{{
                        itemItem.contactTodoMomentVo.momentContent
                      }}</span>
                    </div>
                    <div
                      class="backlog-img-list"
                      v-if="
                        itemItem.contactTodoMomentVo?.filesRecordsList?.length >
                        0
                      "
                    >
                      <a-image-preview-group
                        :preview="{
                          imageGroupVisiable,
                          onVisibleChange: vis => (imageGroupVisiable = vis),
                        }"
                      >
                        <template
                          v-for="(imgItem, imgIndex) in itemItem
                            .contactTodoMomentVo.filesRecordsList"
                          :key="imgIndex"
                        >
                          <div
                            class="backlog-img-item"
                            v-if="
                              imgItem.suffix.toLowerCase() == 'jpg' ||
                              imgItem.suffix.toLowerCase() == 'jpeg' ||
                              imgItem.suffix.toLowerCase() == 'png' ||
                              imgItem.suffix.toLowerCase() == 'svg' ||
                              imgItem.suffix.toLowerCase() == 'gif'
                            "
                          >
                            <a-dropdown>
                              <img
                                class="menu-btn"
                                src="@/assets/images/timeline/menu-btn.png"
                                alt=""
                              />
                              <template #overlay>
                                <a-menu>
                                  <!-- <a-menu-item>
                                  <a href="javascript:;" style="color: #3d3d3d"
                                    >查看大图</a
                                  >
                                </a-menu-item> -->
                                  <a-menu-item>
                                    <a
                                      :href="imgItem.dataUrlCustom"
                                      :download="imgItem.originalFileName"
                                      style="color: #3d3d3d"
                                      >下载</a
                                    >
                                  </a-menu-item>
                                  <a-menu-item
                                    @click="openFileDeleteModa(imgItem.id)"
                                  >
                                    <a
                                      href="javascript:;"
                                      style="color: #3d3d3d"
                                      >删除</a
                                    >
                                  </a-menu-item>
                                </a-menu>
                              </template>
                            </a-dropdown>
                            <a-image
                              :width="184"
                              :height="104"
                              :src="imgItem.dataUrlCustom"
                              @click="imageGroupVisiable = true"
                            />
                          </div>
                        </template>
                      </a-image-preview-group>
                    </div>
                    <div
                      class="backlog-file-list"
                      v-if="
                        itemItem.contactTodoMomentVo?.filesRecordsList?.length >
                        0
                      "
                    >
                      <template
                        v-for="(fileItem, fileIndex) in itemItem
                          .contactTodoMomentVo.filesRecordsList"
                        :key="fileIndex"
                      >
                        <div
                          class="backlog-file-item"
                          :class="fileItem.suffix.toLowerCase()"
                          v-if="
                            fileItem.suffix.toLowerCase() != 'jpg' &&
                            fileItem.suffix.toLowerCase() != 'jpeg' &&
                            fileItem.suffix.toLowerCase() != 'png' &&
                            fileItem.suffix.toLowerCase() != 'svg' &&
                            fileItem.suffix.toLowerCase() != 'gif'
                          "
                        >
                          <img
                            class="backlog-file-icon"
                            src="@/assets/images/timeline/zip-icon.png"
                            alt=""
                            v-if="fileItem.suffix.toLowerCase() == 'zip'"
                          />
                          <img
                            class="backlog-file-icon"
                            src="@/assets/images/timeline/doc-icon.png"
                            alt=""
                            v-else-if="fileItem.suffix.toLowerCase() == 'doc'"
                          />
                          <img
                            class="backlog-file-icon"
                            src="@/assets/images/timeline/mp3-icon.png"
                            alt=""
                            v-else-if="fileItem.suffix.toLowerCase() == 'mp3'"
                          />
                          <img
                            class="backlog-file-icon"
                            src="@/assets/images/timeline/mp4-icon.png"
                            alt=""
                            v-else-if="fileItem.suffix.toLowerCase() == 'mp4'"
                          />
                          <img
                            class="backlog-file-icon"
                            src="@/assets/images/timeline/pdf-icon.png"
                            alt=""
                            v-else-if="fileItem.suffix.toLowerCase() == 'pdf'"
                          />
                          <img
                            class="backlog-file-icon"
                            src="@/assets/images/timeline/ppt-icon.png"
                            alt=""
                            v-else-if="fileItem.suffix.toLowerCase() == 'ppt'"
                          />
                          <img
                            class="backlog-file-icon"
                            src="@/assets/images/timeline/xlsx-icon.png"
                            alt=""
                            v-else-if="fileItem.suffix.toLowerCase() == 'xlsx'"
                          />
                          <img
                            class="backlog-file-icon"
                            src="@/assets/images/timeline/file-icon.png"
                            alt=""
                            v-else
                          />
                          <div class="backlog-file-message">
                            <span class="backlog-file-title">{{
                              fileItem.originalFileName
                            }}</span>
                            <span class="backlog-file-size">{{
                              (fileItem.size / 1024 / 1024).toFixed(2) + 'MB'
                            }}</span>
                          </div>
                          <a-dropdown>
                            <img
                              class="menu-btn"
                              src="@/assets/images/timeline/menu-btn-white.png"
                              alt=""
                            />
                            <template #overlay>
                              <a-menu>
                                <a-menu-item>
                                  <a
                                    :href="fileItem.dataUrlCustom"
                                    :download="fileItem.originalFileName"
                                    style="color: #3d3d3d"
                                    >下载</a
                                  >
                                </a-menu-item>
                                <a-menu-item
                                  @click="openFileDeleteModa(fileItem.id)"
                                >
                                  <a href="javascript:;" style="color: #3d3d3d"
                                    >删除</a
                                  >
                                </a-menu-item>
                              </a-menu>
                            </template>
                          </a-dropdown>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="btn-group">
                    <a-button
                      size="small"
                      @click="openChildEditModa(itemItem.contactTodoMomentVo)"
                    >
                      <!-- <template #icon>
                        <EditOutlined style="color: #575b66" />
                      </template> -->
                      <img
                        style="width: 16px; height: 16px"
                        src="@/assets/images/reminder/edit.png"
                        alt=""
                        class="editImg"
                      />
                    </a-button>
                    <a-button
                      size="small"
                      @click="
                        opendeleteModa(itemItem.contactTodoMomentVo.id, 0)
                      "
                    >
                      <template #icon>
                        <DeleteOutlined style="color: #575b66" />
                      </template>
                    </a-button>
                    <!-- <a-button size="small">
                    <template #icon>
                      <PlusOutlined style="color: #575b66" />
                    </template>
                  </a-button> -->
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="groupItem?.contactTodoMomentVoList?.length > 0">
            <div
              class="backlog-item"
              v-for="(itemItem, itemIndex) in groupItem.contactTodoMomentVoList"
              :key="itemIndex"
            >
              <div class='momentTitle'>{{itemItem?.momentTitle}}</div>
              <div class="item-header">
                <div class="backlog-type">
                  <span>{{ getRecordTypeName(itemItem.momentType) }}</span>
                </div>
              </div>
              <div class="row">
                <div
                  class="avatar-list"
                  :class="{
                    showMore:
                      itemItem?.momentContactVOList?.length >
                      (itemItem?.personnelType ? 4 : 7),
                  }"
                >
                  <div
                    v-if="itemItem.personnelType === 1"
                    class="flexCalss"
                    :class="{
                      normalType: !itemItem.personnelType,
                      circleType: itemItem.personnelType,
                    }"
                  >
                    <div class="avatarClass" v-if="itemItem.connections">
                      <component
                        :is="UserOutlined"
                        class="iconClass"
                        @click="
                          handleGoToContact(
                            itemItem.connections,
                            itemItem.personnelType
                          )
                        "
                      >
                      </component>
                      <div
                        class="textMl mr-8"
                        @click="
                          handleGoToContact(
                            itemItem.connections,
                            itemItem.personnelType
                          )
                        "
                      >
                        {{ itemItem.connections.connectionsName }}
                      </div>
                    </div>
                    <div
                      class="avatarClass"
                      v-for="i in itemItem?.momentContactVOList"
                      :key="i.id"
                    >
                      <a-avatar
                        v-if="i.avatar"
                        :src="setAvatar(i.avatar)"
                        @click="handleGoToContact(i)"
                        class="headerUrl"
                      />
                      <div
                        v-else
                        @click="handleGoToContact(i)"
                        class="headerText headerTextC"
                      >
                        <div v-if="i.name">
                          {{ i.name[0] }}
                        </div>
                      </div>
                      <div
                        class="textMl"
                        @click="handleGoToContact(i)"
                        v-if="itemItem?.momentContactVOList?.length === 1"
                      >
                        {{ i.name }}
                      </div>
                    </div>
                  </div>
                  <div v-else class="flexCalss">
                    <div
                      class="avatarClass"
                      v-for="i in itemItem?.momentContactVOList"
                      :key="i.id"
                    >
                      <a-avatar
                        v-if="i.avatar"
                        :src="setAvatar(i.avatar)"
                        @click="handleGoToContact(i)"
                        class="headerUrl"
                      />
                      <div
                        v-else
                        @click="handleGoToContact(i)"
                        class="headerText"
                      >
                        <div v-if="i.name">
                          {{ i.name[0] }}
                        </div>
                      </div>
                      <div
                        class="textMl"
                        @click="handleGoToContact(i)"
                        v-if="itemItem?.momentContactVOList?.length === 1"
                      >
                        {{ i.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item-content type-auto">
                  <div class="backlog-title" @click="openDetailModa(itemItem)">
                    <!-- <span>人脉宝盒1.0版本研发启动。</span> -->
                  </div>
                  <div
                    class="backlog-content"
                    @click="openDetailModa(itemItem)"
                  >
                    <span>{{ itemItem.momentContent }}</span>
                  </div>
                  <div
                    class="backlog-img-list"
                    v-if="itemItem?.filesRecordsList?.length > 0"
                  >
                    <a-image-preview-group
                      :preview="{
                        imageGroupVisiable,
                        onVisibleChange: vis => (imageGroupVisiable = vis),
                      }"
                    >
                      <template
                        v-for="(imgItem, imgIndex) in itemItem.filesRecordsList"
                        :key="imgIndex"
                      >
                        <div
                          class="backlog-img-item"
                          v-if="
                            imgItem.suffix.toLowerCase() == 'jpg' ||
                            imgItem.suffix.toLowerCase() == 'jpeg' ||
                            imgItem.suffix.toLowerCase() == 'png' ||
                            imgItem.suffix.toLowerCase() == 'svg' ||
                            imgItem.suffix.toLowerCase() == 'gif'
                          "
                        >
                          <a-dropdown>
                            <img
                              class="menu-btn"
                              src="@/assets/images/timeline/menu-btn.png"
                              alt=""
                            />
                            <template #overlay>
                              <a-menu>
                                <!-- <a-menu-item>
                                  <a href="javascript:;" style="color: #3d3d3d"
                                    >查看大图</a
                                  >
                                </a-menu-item> -->
                                <a-menu-item>
                                  <a
                                    :href="imgItem.dataUrlCustom"
                                    :download="imgItem.originalFileName"
                                    style="color: #3d3d3d"
                                    >下载</a
                                  >
                                </a-menu-item>
                                <a-menu-item
                                  @click="openFileDeleteModa(imgItem.id)"
                                >
                                  <a href="javascript:;" style="color: #3d3d3d"
                                    >删除</a
                                  >
                                </a-menu-item>
                              </a-menu>
                            </template>
                          </a-dropdown>
                          <a-image
                            :width="184"
                            :height="104"
                            :src="imgItem.dataUrlCustom"
                            @click="imageGroupVisiable = true"
                          />
                        </div>
                      </template>
                    </a-image-preview-group>
                  </div>
                  <div
                    class="backlog-file-list"
                    v-if="itemItem?.filesRecordsList?.length > 0"
                  >
                    <template
                      v-for="(fileItem, fileIndex) in itemItem.filesRecordsList"
                      :key="fileIndex"
                    >
                      <div
                        class="backlog-file-item"
                        :class="fileItem.suffix.toLowerCase()"
                        v-if="
                          fileItem.suffix.toLowerCase() != 'jpg' &&
                          fileItem.suffix.toLowerCase() != 'jpeg' &&
                          fileItem.suffix.toLowerCase() != 'png' &&
                          fileItem.suffix.toLowerCase() != 'svg' &&
                          fileItem.suffix.toLowerCase() != 'gif'
                        "
                      >
                        <img
                          class="backlog-file-icon"
                          src="@/assets/images/timeline/zip-icon.png"
                          alt=""
                          v-if="fileItem.suffix.toLowerCase() == 'zip'"
                        />
                        <img
                          class="backlog-file-icon"
                          src="@/assets/images/timeline/doc-icon.png"
                          alt=""
                          v-else-if="
                            fileItem.suffix.toLowerCase() == 'doc' ||
                            fileItem.type == 'docx'
                          "
                        />
                        <img
                          class="backlog-file-icon"
                          src="@/assets/images/timeline/mp3-icon.png"
                          alt=""
                          v-else-if="fileItem.suffix.toLowerCase() == 'mp3'"
                        />
                        <img
                          class="backlog-file-icon"
                          src="@/assets/images/timeline/mp4-icon.png"
                          alt=""
                          v-else-if="fileItem.suffix.toLowerCase() == 'mp4'"
                        />
                        <img
                          class="backlog-file-icon"
                          src="@/assets/images/timeline/pdf-icon.png"
                          alt=""
                          v-else-if="fileItem.suffix.toLowerCase() == 'pdf'"
                        />
                        <img
                          class="backlog-file-icon"
                          src="@/assets/images/timeline/ppt-icon.png"
                          alt=""
                          v-else-if="
                            fileItem.suffix.toLowerCase() == 'ppt' ||
                            fileItem.type == 'pptx'
                          "
                        />
                        <img
                          class="backlog-file-icon"
                          src="@/assets/images/timeline/xlsx-icon.png"
                          alt=""
                          v-else-if="fileItem.suffix.toLowerCase() == 'xlsx'"
                        />
                        <img
                          class="backlog-file-icon"
                          src="@/assets/images/timeline/file-icon.png"
                          alt=""
                          v-else
                        />
                        <div class="backlog-file-message">
                          <span class="backlog-file-title">{{
                            fileItem.originalFileName
                          }}</span>
                          <span class="backlog-file-size">{{
                            (fileItem.size / 1024 / 1024).toFixed(2) + 'MB'
                          }}</span>
                        </div>
                        <a-dropdown>
                          <img
                            class="menu-btn"
                            src="@/assets/images/timeline/menu-btn-white.png"
                            alt=""
                          />
                          <template #overlay>
                            <a-menu>
                              <a-menu-item>
                                <a
                                  :href="fileItem.dataUrlCustom"
                                  :download="fileItem.originalFileName"
                                  style="color: #3d3d3d"
                                  >下载</a
                                >
                              </a-menu-item>
                              <a-menu-item
                                @click="openFileDeleteModa(fileItem.id)"
                              >
                                <a href="javascript:;" style="color: #3d3d3d"
                                  >删除</a
                                >
                              </a-menu-item>
                            </a-menu>
                          </template>
                        </a-dropdown>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="btn-group">
                  <a-button size="small" @click="openEditModa(itemItem)">
                    <!-- <template #icon>
                      <EditOutlined style="color: #575b66" />
                    </template> -->
                    <img
                      style="width: 16px; height: 16px"
                      src="@/assets/images/reminder/edit.png"
                      alt=""
                      class="editImg"
                    />
                  </a-button>
                  <a-button
                    size="small"
                    @click="opendeleteModa(itemItem.id, 0)"
                  >
                    <template #icon>
                      <DeleteOutlined style="color: #575b66" />
                    </template>
                  </a-button>
                  <!-- <a-button size="small">
                    <template #icon>
                      <PlusOutlined style="color: #575b66" />
                    </template>
                  </a-button> -->
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="pagination-row" v-if="pageTotal != 0">
      <a-pagination
        v-model:current="pageCurrent"
        v-model:page-size="pageSize"
        :total="pageTotal"
        :show-total="
          (total, range) =>
            `共${total}条，当前显示${range[0] > 0 ? range[0] : 0}-${range[1]}条`
        "
        @change="initData()"
      />
    </div>
    <div class="empty-contain" v-if="pageTotal == 0">
      <a-empty :image="simpleImage" />
    </div>
    <ChildAddModal
      :circleId="editCircleId"
      :parentData="childModalParentData"
      :visiable="childAddShow"
      @closeModal="childModalClose"
    ></ChildAddModal>
    <DetailModal
      :detailData="detailModalData"
      :visiable="detailModalShow"
      @closeModal="detailModalClose"
    ></DetailModal>
    <EditModal
      :circleId="editCircleId"
      :recordData="editModalData"
      :visiable="editModalShow"
      @closeModal="editModalClose"
    ></EditModal>
    <ChildEditModal
      :circleId="editCircleId"
      :recordData="editChildModalData"
      :visiable="editChildModalShow"
      @closeModal="editChildModalClose"
    ></ChildEditModal>
    <DeleteModal
      :deleteId="deleteModalId"
      :visiable="deleteModalShow"
      :type="deleteModalType"
      @closeModal="deleteModalClose"
    ></DeleteModal>
    <FileDeleteModal
      :deleteId="fileDeleteModalId"
      :visiable="fileDeleteModalShow"
      @closeModal="fileDeleteModalClose"
    ></FileDeleteModal>
    <RebackModal
      :userData="rebackModalData"
      :visiable="rebackModalShow"
      @closeModal="rebackModalClose"
    ></RebackModal>
  </div>
</template>

<script setup>
import { onMounted, ref, defineExpose, defineProps, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  Select as ASelect,
  DatePicker,
  Image as AImage,
  Dropdown as ADropdown,
  Menu as AMenu,
  Pagination as APagination,
  Empty as AEmpty,
  Avatar as AAvatar,
} from 'ant-design-vue';
import {
  CalendarOutlined,
  RedoOutlined,
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
  UserOutlined,
} from '@ant-design/icons-vue';
import ChildAddModal from '@/views/timeline/components/childAddModal.vue';
import DetailModal from '@/views/timeline/components/detailModal.vue';
import DeleteModal from '@/views/timeline/components/deleteModal.vue';
import FileDeleteModal from '@/views/timeline/components/fileDeleteModal.vue';
import EditModal from '@/views/timeline/components/editModal.vue';
import ChildEditModal from '@/views/timeline/components/childEditModal.vue';
import RebackModal from '@/views/timeline/components/rebackModal.vue';

import timelineApi from '@/api/timeline';

import { setAvatar } from '@/utils';

const weekdays = [
  '星期日',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
];

const route = useRoute();
const router = useRouter();

const props = defineProps({
  contactId: String,
  circleId: String,
});

const ARangePicker = DatePicker.RangePicker;
const AMenuItem = AMenu.Item;
const AImagePreviewGroup = AImage.PreviewGroup;
const ASelectOption = ASelect.Option;

const simpleImage = AEmpty.PRESENTED_IMAGE_SIMPLE;

const detailModalShow = ref(false);
const detailModalData = ref();

const editCircleId = ref();
const editModalShow = ref(false);
const editModalData = ref();

const editChildModalShow = ref(false);
const editChildModalData = ref();

const deleteModalShow = ref(false);
const deleteModalId = ref();
const deleteModalType = ref(0);

const fileDeleteModalShow = ref(false);
const fileDeleteModalId = ref();

const rebackModalShow = ref(false);
const rebackModalData = ref();

const childAddShow = ref(false);
const childModalParentData = ref();

const pageCurrent = ref(1);
const pageSize = ref(10);
const pageTotal = ref(0);
const isCurrent = ref(1);

const recordList = ref([]);

const imageGroupVisiable = ref(false);

const backlogType = ref();
const backlogTypeList = ref([]);

const backlogDate = ref();

//唤起添加子项弹窗
const openChildModa = data => {
  childModalParentData.value = data;
  childAddShow.value = true;
};

//唤起详情弹窗
const openDetailModa = data => {
  console.log('data时刻', data)
  detailModalData.value = data;
  detailModalShow.value = true;
};

//唤起修改弹窗
const openEditModa = data => {
  if (data.personnelType == 1) {
    editCircleId.value = data.connectionsId;
  } else {
    editCircleId.value = null;
  }
  editModalData.value = data;
  editModalShow.value = true;
};

//唤起修改子项弹窗
const openChildEditModa = data => {
  if (data.personnelType == 1) {
    editCircleId.value = data.connectionsId;
  }
  editChildModalData.value = data;
  editChildModalShow.value = true;
};

//唤起删除子项弹窗
const opendeleteModa = (id, type) => {
  deleteModalId.value = id;
  deleteModalShow.value = true;
  deleteModalType.value = type;
};

//唤起文件删除子项弹窗
const openFileDeleteModa = id => {
  console.log(id);
  fileDeleteModalId.value = id;
  fileDeleteModalShow.value = true;
};

//唤起回退子项弹窗
const openRebackModa = data => {
  rebackModalData.value = data;
  rebackModalShow.value = true;
};

//子记录添加回调事件
const childModalClose = e => {
  childAddShow.value = false;
  editCircleId.value = null;
  if (e) {
    console.log('数据刷新');
    initData();
  }
};

//详情弹窗回调事件
const detailModalClose = e => {
  detailModalShow.value = false;
  editCircleId.value = null;
};

//修改记录回调事件
const editModalClose = e => {
  editModalShow.value = false;
  editCircleId.value = null;
  initData();
};

//修改子项记录回调事件
const editChildModalClose = e => {
  editChildModalShow.value = false;
  editCircleId.value = null;
  initData();
};

//删除记录回调事件
const deleteModalClose = (e, delLength) => {
  deleteModalShow.value = false;
  if (e) {
    console.log('数据刷新', delLength);
    refreshGrid(delLength);
  }
};

//文件删除记录回调事件
const fileDeleteModalClose = e => {
  fileDeleteModalShow.value = false;
  if (e) {
    console.log('数据刷新');
    initData();
  }
};

//回退记录回调事件
const rebackModalClose = e => {
  rebackModalShow.value = false;
  if (e) {
    console.log('数据刷新');
    initData();
  }
};

const searchBacklog = value => {
  let searchArr = value.search;
  initData(searchArr, 1);

};

//获取记录类型列表
const getRecordType = () => {
  timelineApi.getRecordType('', {}).then(res => {
    if (res) {
      backlogTypeList.value = res;
    }
  });
};

//根据记录类型code获取类型名称
const getRecordTypeName = code => {
  let arr = '';
  backlogTypeList.value.forEach(item => {
    if (item.itemCode == code) {
      arr = item.itemNameCn;
    }
  });
  return arr;
};

//获取页面数据
// initdata 的current 通过传参进来

const initData = (searchArr, current) => {
  timelineApi
    .getRecordList('', {
      contactId: props.contactId,
      connectionsId: props.circleId,
      // 传参进来
      current: current ? current : pageCurrent.value,
      size: pageSize.value,
      momentType: backlogType.value,
      personnelType: props.circleId ? 1 : null,
      startTime: backlogDate.value ? dateFormat(backlogDate?.value[0]?._d) : '',
      endTime: backlogDate.value ? dateFormat(backlogDate?.value[1]?._d) : '',
      momentContent: searchArr ? searchArr : null,
    })
    .then(res => {
      if (res) {
        console.log('res记录刷新', res);
        pageTotal.value = res.total;
        recordList.value = res.records;
        isCurrent.value = parseInt(res.current);
      }
    });
};

// 分页处理
const refreshGrid = delLength => {
  // 需要判断 recordList.value 长度是小于等于 1，表示列表只有一条数据
  // 判断 contactTodoMomentVoList 长度是不是 小于等于 1， 表示记录只有一条数据
  if (recordList.value.length <= 1) {
    if (recordList.value[0].contactTodoMomentVoList.length <= 1) {
      // 列表删除掉的个数 delLength
      let newTotal = pageTotal.value - delLength;
      // newTotalToStr 字符串后面的数字是0， 表示这页删除完，回到第一页去
      let newTotalToStr = newTotal
        .toString()
        .charAt(newTotal.toString().length - 1);
      // console.log('分页', pageTotal.value, typeof pageTotal.value, )
      // 最后一页删除后，跳转会第一页
      if (newTotalToStr === '0') {
        pageCurrent.value = 1;
      }
      initData('', newTotalToStr === '0' ? 1 : isCurrent.value);
    } else {
      initData();
    }
  } else {
    initData();
  }
};

//日期格式化
const dateFormat = now => {
  if (!now) {
    return '';
  }
  const year = now.getFullYear();
  const month =
    now.getMonth() + 1 >= 10 ? now.getMonth() + 1 : '0' + (now.getMonth() + 1);
  const day = now.getDate() >= 10 ? now.getDate() : '0' + now.getDate();

  return `${year}-${month}-${day} 00:00:00`;
};

const handleGoToContact = (record, type) => {
  if (type === 1) {
    // 点击脉圈跳转去脉圈详情
    router.push(`/contactsCircle/${record.parentId}/${record.id}`);
    // router.push(`/contacts/${record.id}`);
  } else {
    router.push(`/contacts/${record.id}`);
  }
};

watch(
  () => backlogDate.value,
  (newVal, oldVal) => {
    initData();
  }
);

onMounted(() => {
  if (props.circleId) {
    editCircleId.value = props.circleId;
  }
  getRecordType();
  initData();
});

defineExpose({ initData });
</script>

<style lang="less" scoped>
.row {
  display: flex;
  flex-direction: row;
}
.container {
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .container-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 12px;
  }

  .backlog-list {
    width: 100%;
    margin-top: 13px;
    padding-left: 16px;
    padding-bottom: 10px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    .dashline {
      width: 1px;
      height: 100%;
      border: 1px dashed #ff7b00;
      position: absolute;
      top: 34px;
      left: 3px;
    }

    .backlog-group:not(:first-child) {
      margin-top: 16px;
    }

    .backlog-group {
      width: 98%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: #ffffff;
      position: relative;
      gap: 16px;

      .group-header {
        width: 100%;
        height: 40px;
        padding: 0 8px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        .backlog-time {
          color: #6b7592;
          font-size: 12px;
          font-weight: 400;
        }

        .backlog-day {
          color: #6b7592;
          font-size: 12px;
          font-weight: 400;
        }
      }

      .backlog-item {
        width: 100%;
        // min-height: 200px;
        padding: 12px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 8px;
        transition: background 0.3s;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        position: relative;

        .child-item {
          width: 100%;
          padding: 17px 0;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .momentTitle {
          font-weight: bold;
          margin-bottom: 12px;
        }

        .item-header {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;

          .backlog-type {
            // width: 48px;
            height: 24px;
            padding: 0 12px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ff7b00;
            border-radius: 4px;

            span {
              color: #ff7b00;
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
            }
          }
        }

        .row {
          width: 100%;
          flex-direction: row;
          align-items: flex-start;
          position: relative;

          .item-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .backlog-title {
              width: 435px;
              padding: 13px 12px;
              box-sizing: border-box;

              span {
                width: 100%;
                color: #333333;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.5;
                word-break: break-word;
              }
            }

            .backlog-content {
              width: 435px;
              padding: 0 12px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              span {
                width: 100%;
                display: block;
                color: #333333;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                white-space: pre-wrap;
                word-break: break-word;
              }

              .row {
                width: 100%;
                padding: 13px 0;
                display: flex;
                flex-direction: row;
                box-sizing: border-box;
                align-items: center;
                gap: 8px;

                img {
                  width: 14px;
                  height: 14px;
                  object-fit: cover;
                }
              }
            }

            .backlog-img-list {
              width: 103.5%;
              margin-top: 13px;
              padding: 0 8px;
              display: flex;
              flex-direction: row;
              align-items: center;
              flex-wrap: wrap;
              gap: 16px;

              .backlog-img-item {
                //width: 184px;
                height: 104px;
                border-radius: 4px;
                overflow: hidden;
                position: relative;

                &:hover {
                  .menu-btn {
                    opacity: 1;
                    transition: opacity 0.3s;
                  }
                }

                .menu-btn {
                  width: 24px;
                  height: 24px;
                  object-fit: cover;
                  opacity: 0;
                  transition: opacity 0.3s;
                  cursor: pointer;
                  position: absolute;
                  top: 0;
                  right: 0;
                  z-index: 2;
                }

                ::v-deep(.ant-image-img) {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .backlog-img-item /deep/ .ant-image {
                height: 104px;

              }

            }

            .backlog-file-list {
              width: 100%;
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 16px;

              .backlog-file-item {
                width: 103.5%;
                padding: 16px 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-radius: 8px;
                transform: translateX(25px);
                background: #eaefff;

                &.zip,
                &.mp3 {
                  background: #faedff;
                }

                &.xlsx {
                  background: #e7fff5;
                }

                &.ppt,
                &.pdf {
                  background: #ffe5ce;
                }

                &.doc,
                &.mp4 {
                  background: #eaefff;
                }

                .backlog-file-icon {
                  width: 32px;
                  height: 32px;
                  object-fit: cover;
                }

                .backlog-file-message {
                  margin-left: 8px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;

                  .backlog-file-title {
                    color: #333333;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1;
                  }

                  .backlog-file-size {
                    color: #999999;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1;
                  }
                }

                .menu-btn {
                  width: 24px;
                  height: 24px;
                  margin-left: auto;
                  object-fit: cover;
                  opacity: 0;
                  transition: opacity 0.3s;
                  cursor: pointer;
                }

                &:hover {
                  .menu-btn {
                    opacity: 1;
                    transition: opacity 0.3s;
                  }
                }
              }
            }
          }

          .btn-group {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            opacity: 0;
            transition: opacity 0.3s;
          }

          &:hover {
            .btn-group {
              opacity: 1;
              transition: opacity 0.3s;
            }
          }
        }

        &:hover {
          background: rgba(#ff7b00, 0.1);
          transition: background 0.3s;
        }
      }

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border: 2px solid #ff7b00;
        background: #ffffff;
        border-radius: 50%;
        position: absolute;
        top: 16px;
        left: -16px;
        z-index: 2;
      }
    }
  }

  .pagination-row {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    ::v-deep(.ant-pagination-item-active a) {
      color: #ffffff;
    }
  }
}

.avatar-list {
  // width: 195px;
  position: absolute;
  top: 10px;
  left: 459px;

  &.showMore {
    &::after {
      display: block;
    }
  }

  &::after {
    content: '...';
    display: none;
    color: #ff7b00;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(120%, -30%);
  }

  .flexCalss {
    width: 195px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.circleType {
      width: 232px;


    }
  }

  .avatarClass {
    //width: 400px;
    //width: 294px;
    margin-right: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: auto;
    .headerText {
      min-width: 26px;
    }
    .textMl {
      margin-left: 8px;
      color: rgba(0, 0, 0, 0.9);
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.mr-8 {
        width: 80px;
      }
    }
  }

  .headerUrl {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    min-width: 24px;
  }
}

.headerTextC {
  background: #ffc85b;
  min-width: 26px;
}

.iconClass {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffc85b;
  color: #ffffff;
  text-align: center;
  padding-top: 4px;
  flex-shrink: 0;
}

.empty-contain {
  width: 100%;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .ant-btn-sm {
  padding: 0 3px;
}
</style>
