<template>
  <div class="modal-container">
    <a-modal
      width="750px"
      :bodyStyle="bodystyle"
      :visible="visiable"
      title="新增待办记录"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form
        ref="formRef"
        layout="vertical"
        style="width: 100%"
        :model="formData"
        :rules="formRules"
      >
        <a-form-item label="" name="title">
          <a-input
            v-model:value="formData.title"
            placeholder="请输入记录标题"
          />
        </a-form-item>
        <a-form-item label="" name="content">
          <a-textarea
            v-model:value="formData.content"
            placeholder="请输入记录内容"
            showCount
            :maxlength="800"
            :rows="4"
          />
        </a-form-item>
        <contactList
          ref="contactListRef"
          :circleId="circleId"
          :selectList="chosenContactList"
          :selectIdList="formData.contactIdList"
          @changeList="changeContactList"
        ></contactList>
        <a-row style="gap: 16px">
          <a-form-item label="" name="recordDate">
            <a-date-picker
              v-model:value="formData.recordDate"
              :getCalendarContainer="triggerNode => triggerNode.parentNode"
              value-format="yyyy-MM-DD HH:mm:ss"
              show-time
              placeholder="请选择时间"
              style="width: 218px"
              class="phoneCalssRight"
              :inputReadOnly="true"
              disabled
            />
          </a-form-item>
          <a-form-item label="" name="recordType">
            <a-select
              style="width: 218px"
              v-model:value="formData.recordType"
              placeholder="请选择记录类型"
            >
              <template v-for="(item, index) in recordTypeList" :key="index">
                <a-select-option label="" :value="item.itemCode">
                  {{ item.itemNameCn }}
                </a-select-option>
              </template>
            </a-select>
          </a-form-item>
        </a-row>
        <div class="space-tip" v-if="isSpaceFilled">
          <img src="@/assets/images/timeline/warn-icon-red.png" alt="" />
          <span
            >您上传的文件大小超过您云空间的可用大小，请扩容后再进行上传。</span
          >
          <div class="link-btn" @click="toAdd">
            <span>去扩容</span>
          </div>
        </div>
        <a-form-item
          label=""
          class="formFileRow"
          v-if="casualFileList.length > 0"
        >
          <fileList
            :fileList="casualFileList"
            @fileListChange="deleteFile"
          ></fileList>
        </a-form-item>
        <a-form-item label="" class="formFileRow">
          <a-upload
            v-model:file-list="casualFileList"
            list-type="picture-card"
            @preview="filePreview"
            :beforeUpload="fileUploadBefore"
            :action="fileUploadSrc"
            :headers="fileUploadHeaders"
            :data="
              file => ({
                bizType: file.name.slice(file.name.lastIndexOf('.') + 1),
              })
            "
            @change="fileListChange"
          >
            <plus-outlined />
            <div style="margin-top: 8px">上传文件</div>
          </a-upload>
          <a-modal
            :open="filePreviewVisible"
            :title="filePreviewTitle"
            :footer="null"
            @cancel="filePreviewCancel"
          >
            <img alt="example" style="width: 100%" :src="filePreviewImage" />
          </a-modal>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {
  Form as AForm,
  Row as ARow,
  DatePicker as ADatePicker,
  Select as ASelect,
  Upload as AUpload,
  message,
  Empty as AEmpty,
} from 'ant-design-vue';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons-vue';
import fileList from './fileList.vue';
import contactList from './contactList.vue';
import timelineApi from '@/api/timeline';

const AFormItem = AForm.Item;
const ASelectOption = ASelect.Option;

const store = useStore();
const router = useRouter();

const props = defineProps({
  visiable: Boolean,
  parentData: Object,
  circleId: String,
});

const emits = defineEmits(['closeModal']);

const formRef = ref();
const formData = ref({
  title: '',
  id: '',
  content: [],
  contactIdList: [],
  recordDate: '',
  recordType: '',
  fileList: [],
});
const formRules = {
  content: [
    { required: true, message: '请输入记录内容', trigger: 'change' },
    { min: 1, max: 800, message: '记录内容不能超过800字', trigger: 'blur' },
  ],
  title:[
    { required: true, message: '请输入记录标题', trigger: 'change' },
    {  max: 30, message: '记录标题不能超过30字', trigger: 'blur' },
  ],
  recordType: [
    { required: true, message: '请选择记录类型', trigger: 'change' },
  ],
};

const contactListRef = ref();
const isSpaceFilled = ref(false);
const allSpace = ref(0);
const remainingSpace = ref(0);
const recordTypeList = ref([]);
const filePreviewImage = ref('');
const filePreviewVisible = ref(false);
const fileUploadSrc = `${process.env.VUE_APP_API_SERVER}connections_treasure_box/obs/obs-files-records/uploadObsFiles`;
const { token, tenant } = store.state.account;
const fileUploadHeaders = { token, tenant };
const filePreviewTitle = ref('');
const fileUploadingSpace = ref(0);
const casualFileList = ref([]);
const chosenContactList = ref([]);

const fileUploading = ref(false);

const bodystyle = {
  maxHeight: '750px',
  overflow: 'hidden',
  overflowY: 'scroll',
};

// 防抖函数 做节流处理
const debounce = (fn, time = 1000) => {
  let timer;
  return (...arges) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...arges);
    }, time);
  };
};

const handleOk = () => {
  if (fileUploading.value) {
    message.warning('上传中，请稍后');
    return;
  }
  if (!props.circleId) {
    formData.value.contactIdList = [];
    chosenContactList.value.map(item => {
      formData.value.contactIdList.push(item.id);
    });
  }
  debounce(submitData());
};

const clean = () => {
  formData.value = {
    content: '',
    contactIdList: [],
    recordDate: '',
    recordType: '',
    fileList: [],
  };
  casualFileList.value = [];
  fileUploadingSpace.value = 0;
  chosenContactList.value = [];
  contactListRef.value.cleanList();
};

const handleCancel = () => {
  emits('closeModal', false);
  clean();
};

const toAdd = () => {
  router.push(`/personal`);
};

//数据提交
const submitData = () => {
  formRef.value.validate().then(() => {
    timelineApi
      .addRecord('', {
        userId: store.state.account.user.id,
        momentTitle: formData.value.title,
        momentContent: formData.value.content,
        momentTime: formData.value.recordDate,
        momentType: formData.value.recordType,
        personnelType: props.circleId ? 1 : 0,
        connectionsId: props.circleId,
        obsFilesRecordsList: formData.value.fileList,
        contactIdList: formData.value.contactIdList,
        todoId: formData.value.id,
      })
      .then(res => {
        if (res) {
          fileUploadingSpace.value = 0;
          message.success('新建待办子记录成功');
          emits('closeModal', true);
          clean();
        } else {
          // message.error('新建失败');
        }
      });
  });
};

const changeContactList = e => {
  if (e.type) {
    formData.value.contactIdList = e.data;
  } else {
    chosenContactList.value = e.data;
  }
};

//获取记录类型列表
const getRecordType = () => {
  timelineApi.getRecordType('', {}).then(res => {
    if (res) {
      recordTypeList.value = res.filter(item => item.id == 2095);
    }
  });
};

//获取云空间大小
const getUserFreeSpace = () => {
  timelineApi.getUserFreeSpace('', {}).then(res => {
    if (res) {
      allSpace.value = Number(res.allSpace);
      remainingSpace.value = Number(res.remainingSpace);
    }
  });
};

//文件上传前检查
const fileUploadBefore = file => {
  return new Promise((resolve, reject) => {
    let filesize = file.size;
    fileUploading.value = true;

    if (
      file.type == 'application/octet-stream' ||
      file.type == 'application/x-msdownload' ||
      file.type == 'application/javascript' ||
      file.type == 'application/vnd.ms-word.document.macroenabled.12' ||
      file.type == 'application/vnd.ms-excel.sheet.macroenabled.12' ||
      file.type == 'application/x-shockwave-flash'
    ) {
      fileUploading.value = false;
      return reject(false);
    }

    console.log(
      '用户总存储空间:' +
        allSpace.value +
        ';已用空间:' +
        remainingSpace.value +
        ';本次上传文件大小:' +
        filesize +
        ';缓冲区文件大小:' +
        fileUploadingSpace.value
    );
    if (
      remainingSpace.value + fileUploadingSpace.value + filesize >
      allSpace.value
    ) {
      fileUploading.value = false;
      isSpaceFilled.value = true;
      return reject(false);
    } else {
      isSpaceFilled.value = false;
      fileUploadingSpace.value += filesize;
    }

    resolve(true);
  });
};

//文件列表上传回调
const fileListChange = e => {
  // console.log(e.file);
  if (e.file.status === 'done') {
    if (e.file.response.code == 0) {
      formData.value.fileList.push(e.file.response.data);
      fileUploading.value = false;
    }
  }
  if (e.file.status === 'removed') {
    // console.log('file removed');
    let fileId = e.file.response.data.id;

    formData.value.fileList = formData.value.fileList.filter(
      item => item.id != fileId
    );

    fileUploadingSpace.value -= e.file.size;
  }
};

//文件列表删除文件回调
const deleteFile = e => {
  casualFileList.value = e.fileList;
  formData.value.fileList.splice(e.index, 1);
  fileUploadingSpace.value -= e.size;
};

//文件转base64
const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

//关闭文件预览
const filePreviewCancel = () => {
  filePreviewVisible.value = false;
  filePreviewTitle.value = '';
};

//打开文件预览
const filePreview = async file => {
  if (!file.url && !file.preview) {
    file.preview = await getBase64(file.originFileObj);
  }
  filePreviewImage.value = file.url || file.preview;
  filePreviewVisible.value = true;
  filePreviewTitle.value =
    file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
};

onMounted(() => {
  getRecordType();
  getUserFreeSpace();
});

// watch(
//   () => props.parentData,
//   (newVal, oldVal) => {
//     formData.value.id = newVal.id;
//     chosenContactList.value = newVal.personnelVoList;
//     formData.value.recordDate = newVal.reminderTime;
//     formData.value.recordType = 'TODO';

//     if (props.circleId) {
//       formData.value.contactIdList = [];
//       chosenContactList.value.map(item => {
//         formData.value.contactIdList.push(item.id);
//       });
//     }
//   }
// );

watch(
  () => props.visiable,
  (newVal, oldVal) => {
    if (newVal) {
      formData.value.id = props.parentData.id;
      chosenContactList.value = props.parentData.personnelVoList;
      formData.value.recordDate = props.parentData.reminderTime;
      formData.value.recordType = 'TODO';

      if (props.circleId) {
        formData.value.contactIdList = [];
        chosenContactList.value.map(item => {
          formData.value.contactIdList.push(item.id);
        });
      }
    }
  }
);
</script>

<style lang="less" scoped>
.formItemClass {
  position: relative;
}

.chosenList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 4px;
  margin-top: 16px;
  max-height: 68px;
  overflow: auto;

  .name {
    width: 88px;
    height: 24px;
    border-radius: 4px;
    //background: #FFFFFF;
    background: #fffaf6;
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 8px 8px 0px;
  }
}

.searchList {
  max-height: 164px;
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 24px;
  z-index: 2;
  margin-top: 16px;
  border: 1px solid #eeeff5;
  background: #fff;
  border-radius: 4px;

  .selectList {
    .selectName {
      height: 40px;
      //border-radius: 4px;
      background: #f5f6fa;
      font-size: 12px;
      line-height: 20px;
      //padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin: 0px 8px 8px 0px;
      //padding: 10px 16px;
      cursor: pointer;
      padding-left: 16px;

      &:hover {
        background: #fffaf6;
      }
    }
  }
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.space-tip {
  width: 100%;
  margin-bottom: 16px;
  padding: 11px 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(#fa5151, 0.08);
  border-radius: 8px;

  img {
    width: 10.5px;
    height: 10.5px;
    object-fit: cover;
  }

  span {
    margin-left: 9px;
    color: #fa5151;
    font-size: 12px;
    font-weight: 400;
  }

  .link-btn {
    margin-left: auto;
    padding: 3px 11px;
    box-sizing: border-box;
    background: #fa5151;
    border-radius: 4px;

    span {
      margin-left: 0;
      color: #fff;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
    }
  }
}

/deep/ .ant-upload-list {
  display: none !important;
}
</style>
